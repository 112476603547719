<template>
  <div>
    <el-dialog
      title="发券"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="优惠券：" prop="coupon">
            <el-select
              v-model="form.coupon"
              placeholder="请选择"
              filterable
              @change="couponChangeHandle"
            >
              <el-option
                v-for="(item, index) in couponOptions"
                :key="index"
                :label="`[${item.id}] ${item.title}`"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用说明：" prop="instructions">
            <el-input v-model="form.instructions" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { COUPON_USE_TYPE_OPTIONS } from "@/helpers/constants";
import { distribute, getCouponList } from "@/apis/unidrink/coupon";
import { getCustomerList } from "@/apis/user/customer";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { getShopList } from "@/apis/auth";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      COUPON_USE_TYPE_OPTIONS: COUPON_USE_TYPE_OPTIONS,
      initForm: {},
      form: {},
      rules: {
        coupon: [
          { required: true, message: "优惠券不能为空", trigger: "blur" },
        ],
      },
      shopOptions: [],
      couponOptions: [],
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.getCouponOptions();
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      this.form = cloneDeep(this.userData);
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const cond = {
          userId: this.userData.id,
          ...this.form.coupon,
          couponId: this.form.coupon.id,
          instructions: this.form.instructions,
        };
        delete cond.id;

        console.log(cond);

        distribute(cond).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },

    getCouponOptions() {
      getCouponList({ currPage: 1, pageSize: 99, switchFlag: 1 }).then(
        ({ code, result, message }) => {
          if (code === "0000") {
            this.couponOptions = result.list;
          } else {
            msgErr(message);
          }
        }
      );
    },

    couponChangeHandle(coupon) {
      console.log(coupon);
      this.form.instructions = coupon.instructions;
    },
  },
};
</script>

<style lang="scss" scoped></style>
